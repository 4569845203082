import { Button, Flex, List, Popover, Tag, Tooltip, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { TiMicrophoneOutline } from "react-icons/ti";
import { RiMenu2Fill } from "react-icons/ri";
import { RiAttachment2 } from "react-icons/ri";
import { GoSmiley } from "react-icons/go";
import { IoMdTime } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { useEffect, useRef, useState } from "react";
import { UseRecorder } from "../../../interfaces/recorder.type";
import useRecorder from "../../../hooks/recorder/use-Recorder";
import useRecordingsList from "../../../hooks/recorder/user-recordings-list";
import { convertToBase64Array } from "../../../utils/convertToBase64Array";
import { MdDeleteOutline } from "react-icons/md";
import emojiMartData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Media, outgoingMessage } from "../../../interfaces/messages.type";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import generateKey from "../../../utils/generate-key";
import { setSelectedConversation } from "../../../store/slices/conversation.slice";
import useMessageToast from "../../../hooks/error/errorMessage";
import { RcFile } from "antd/es/upload";
import ConnectionWarningModal from "./chat.disconnection";
import ModalWrapper from "../../generic/modal";
import { ScheduleForm } from "../../Form/ScheduleMessage";
import { TemplateType } from "../../../store/slices/templates/templates.slice";
import { useGetTemplates } from "../../../hooks/apis/templates.api";
import { usePermissions } from "../../../hooks/permissions";

const { Text } = Typography;
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const iconButtonStyle: React.CSSProperties = {
  padding: "10px",
  border: "none",
  cursor: "pointer",
};
const iconStyle: React.CSSProperties = {
  height: "1rem",
  width: "1rem",
};

const timerContainer: React.CSSProperties = {
  width: "98%",
  margin: "auto",
};

const ChatTextArea = ({
  sendMessage,
  participants,
  setParticipants,
  setIsNewConversation,
  isSocketConnected,
}: {
  sendMessage?: Function;
  participants: string[];
  setParticipants: Function;
  setIsNewConversation: Function;
  isSocketConnected: boolean;
}) => {
  const { hasPermission } = usePermissions();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListError, setFileListError] = useState<string | null>(null);
  const [disconnectionModal, setDisconnectionModal] = useState(false);

  const { recorderState, ...handlers }: UseRecorder = useRecorder();
  const { audio } = recorderState;
  const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording, cancelRecording } = handlers;
  const { recordings, deleteAudio } = useRecordingsList(audio);
  const [recordingParsed, setRecordingsParsed] = useState([]);
  const [inputText, setInputText] = useState("");
  const [emojiBoxOpen, setEmojiBoxOpen] = useState(false);
  const [addScheduleMessageModal, setAddScheduleMessageModal] = useState(false);
  const [isTemplatesOpen, setIsTemplatesOpen] = useState(false);

  const { selectedConversation } = useSelector(
    (state: RootState) => state.conversation
  );
  const { selectedDid } = useSelector((state: RootState) => state.userDids);
  const { isAdminView } = useSelector((state: RootState) => state.admin);
  const { data: templates } = useSelector(
    (state: RootState) => state.templates
  );
  const { data: user } = useSelector((state: RootState) => state.user);

  const dispatch: AppDispatch = useDispatch();

  const { contextHolder, error, success } = useMessageToast();
  const { refetch: fetchTemplates } = useGetTemplates(
    hasPermission("sms", ["readTemplates:any", "readTemplates:account"])
      ? user?.accountID
      : null
  );

  const TemplatesComponent = () => {
    return (
      <List<TemplateType>
        bordered
        dataSource={templates}
        style={{
          maxHeight: "180px",
          overflowY: "auto",
        }}
        renderItem={(item) => (
          <List.Item
            onClick={() => {
              setInputText((prev) => `${prev} ${item.body}`);
              setIsTemplatesOpen(false);
            }}
          >
            <List.Item.Meta title={item.name} style={{ cursor: "pointer" }} />
          </List.Item>
        )}
      />
    );
  };

  const getRecordings = async (
    recordings: {
      audio: string;
      key: string;
    }[]
  ) => {
    return await convertToBase64Array(recordings);
  };

  useEffect(() => {
    if (templates.length === 0) {
      fetchTemplates();
    }
  }, []);

  useEffect(() => {
    getRecordings(recordings)
      .then((response: any) => {
        setRecordingsParsed(
          response.map((audio: any) => {
            return {
              url: audio,
              filename: "audio",
              content_type: "audio",
            };
          })
        );
      })
      .catch((err) => {
        console.log("Err", err);
      });
  }, [recordings]);

  useEffect(() => {
    if (fileList.length > 0) {
        const totalSize = Array.from(fileList).reduce((total, file: any) => total + file.size, 0);
        if (totalSize > 1024 * 1000) {
            setFileListError("File size should not exceed 1MB");
        } else {
            setFileListError(null);
        }
    } else {
        setFileListError(null);
    }
  }, [fileList]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadRef = useRef<HTMLInputElement>(null);
  const triggerUpload = () => {
    if (!uploadRef.current) return;

    uploadRef.current.click();
  };

  const addEmoji = (e: any) => {
    let sym = e.unified.split("-");
    let codesArray: any = [];
    sym.forEach((el: any) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setInputText(inputText + emoji);
    setEmojiBoxOpen(false);
  };

  const emojiBox = <Picker data={emojiMartData} onEmojiSelect={addEmoji} />;

  const handleMessageSubmit = async () => {
    if (inputText.trim().length === 0 && fileList.length === 0) {
      setInputText("");
      return;
    }

    const text = inputText;
    const media: Media[] = fileList
      .map((file) => {
        const match = file.thumbUrl?.match(/^data:.*;base64,(.+)/);
        if (match && match[1]) {
          return {
            content: match[1] as string,
            filename: file.name,
            content_type: file.type ? file.type : "",
          };
        }
      })
      .filter((file) => file) as Media[];

    if (selectedConversation) {
      const message: outgoingMessage = {
        text: text,
        media: [...media, ...recordingParsed],
        from: selectedConversation.did,
        to: selectedConversation.participant,
        clientId: generateKey(),
      };

      sendMessage && sendMessage(message);
      setRecordingsParsed([]);

      setInputText("");
      setFileList([]);
    } else {
      if (participants.length > 0 && selectedDid) {
        participants.forEach((participant) => {
          const message: outgoingMessage = {
            text: text,
            media: [...media, ...recordingParsed],
            from: selectedDid,
            to: participant,
            clientId: generateKey(),
          };

          sendMessage && sendMessage(message);
        });
        setInputText("");
        setFileList([]);
        setParticipants([]);
        setRecordingsParsed([]);
        setIsNewConversation(false);
        dispatch(
          setSelectedConversation({
            did: selectedDid,
            participant: participants[0],
          })
        );
      }
    }
  };

  const getBase64 = (file: RcFile): Promise<string | ArrayBuffer> =>
    new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error: any) => reject(error);
    });

  return (
    <Flex
      style={{
        padding: "15px",
        position: "absolute",
        width: "100%",
        bottom: 0,
      }}
    >
      {addScheduleMessageModal && (
        <ModalWrapper
          isModalOpen={addScheduleMessageModal}
          setIsModalOpen={setAddScheduleMessageModal}
          title="Schedule Message"
        >
          <ScheduleForm
            did={selectedConversation?.did}
            participant={selectedConversation?.participant}
            setIsModalOpen={setAddScheduleMessageModal}
          />
        </ModalWrapper>
      )}

      {contextHolder}
      {disconnectionModal && (
        <ConnectionWarningModal
          open={disconnectionModal}
          setOpen={setDisconnectionModal}
          handleTryAgain={() => {
            setDisconnectionModal(false);
            handleMessageSubmit();
          }}
        />
      )}
      <Flex
        vertical={true}
        style={{ background: "white", width: "100%", borderRadius: "15px" }}
      >
        <TextArea
          variant="borderless"
          showCount
          style={{ border: "none" }}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key == "Enter" && !e.shiftKey) {
              if (inputText.trim().length === 0 && fileList.length === 0) {
                return;
              }

              if (
                inputText == "" &&
                [...recordingParsed, ...fileList].length == 0 &&
                recordingParsed.length == 0
              ) {
                return;
              } else {
                handleMessageSubmit();
              }
            }
          }}
          placeholder="Write a Text message"
        />

        <Upload
          key={fileList.length}
          style={{ marginLeft: "10px !important", height: "0px", width: "0px" }}
          listType="picture-card"
          fileList={fileList}
          previewFile={getBase64 as any}
          onPreview={handlePreview}
          beforeUpload={() => {
            return false;
          }}
          onChange={file => {
            handleChange(file);
        }}
        >
          <div
            style={{
              height: fileList.length == 0 ? "100px" : "0px",
              width: fileList.length == 0 ? "100px" : "0px",
            }}
            ref={uploadRef}
          ></div>
          {/* {isUpload ? uploadButton : null} */}
        </Upload>
        {fileListError && (
         <Typography.Text type="danger" style={{ display: "block", marginTop: "10px", marginLeft: "10px" }}>
          {fileListError}
         </Typography.Text>
         )}
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        {initRecording && (
          <Tag style={timerContainer} color="processing">
            <Flex justify="space-between">
              <span
                style={{ marginTop: "4px" }}
                onClick={startRecording}
                className="animate-flicker"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  fill="rgb(189, 0, 0)"
                >
                  <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192zm0 224a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
                </svg>
              </span>
              <Flex>
                <Text style={{ color: "rgb(107, 114, 128)" }}>
                  {" "}
                  {recordingMinutes > 9
                    ? recordingMinutes
                    : `0${recordingMinutes}`}
                  :{" "}
                  {recordingSeconds > 9
                    ? recordingSeconds
                    : `0${recordingSeconds}`}
                </Text>
                <MdDeleteOutline
                  style={{ width: "20px", height: "20px", color: "red" }}
                  cursor={"pointer"}
                  onClick={() => {
                    cancelRecording();
                    setRecordingsParsed([]);
                  }}
                />
              </Flex>
            </Flex>
          </Tag>
        )}

        {recordingParsed.map((recording: any, index) => {
          return (
            <Flex>
              <audio
                src={recording.url}
                key={index}
                controls

                // onRemove={(index) => {
                //   setRecordingsParsed([]);
                // }}
              />
              <Flex align="center">
                <MdDeleteOutline
                  style={{ width: "20px", height: "20px" }}
                  cursor={"pointer"}
                  onClick={() => {
                    setRecordingsParsed([]);
                  }}
                />
              </Flex>
            </Flex>
          );
        })}

        <Flex justify="space-between" style={{ padding: "10px" }}>
          <Flex>
            <Popover
              overlayStyle={{ width: "20%" }}
              content={TemplatesComponent}
              onOpenChange={setIsTemplatesOpen}
              title={"Templates"}
              trigger="click"
              open={isTemplatesOpen}
              className="templates-popover"
              style={{ width: "35%" }}
              placement="topLeft"
            >
              <Button style={iconButtonStyle}>
                <RiMenu2Fill style={iconStyle} />
              </Button>
            </Popover>

            {initRecording ? (
              // <span
              //   style={iconButtonStyle}
              //   onClick={startRecording}
              //   className="animate-flicker"
              // >
              //   <svg
              //     xmlns="http://www.w3.org/2000/svg"
              //     height="1em"
              //     viewBox="0 0 512 512"
              //     fill="rgb(189, 0, 0)"
              //   >
              //     <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192zm0 224a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
              //   </svg>
              // </span>
              <Button style={iconButtonStyle} onClick={startRecording}>
                <TiMicrophoneOutline color="red" style={iconStyle} />
              </Button>
            ) : (
              <Button style={iconButtonStyle} onClick={startRecording}>
                <TiMicrophoneOutline style={iconStyle} />
              </Button>
            )}

            <Button
              onClick={() => {
                triggerUpload();
              }}
              style={iconButtonStyle}
            >
              <RiAttachment2 style={iconStyle} />
            </Button>
            <Popover
              content={emojiBox}
              trigger={"click"}
              open={emojiBoxOpen}
              onOpenChange={(open) => setEmojiBoxOpen(open)}
            >
              <Button style={iconButtonStyle}>
                <GoSmiley style={iconStyle} />
              </Button>
            </Popover>
          </Flex>
          <Flex>
            <Tooltip title="Schedule Message">
              <Button
                style={{
                  background: "#130D38",
                  marginRight: "10px",
                  width: "45px",
                }}
                onClick={() => {
                  setAddScheduleMessageModal(true);
                }}
                icon={<IoMdTime color="white" />}
              ></Button>
            </Tooltip>

            <Button
              style={{
                background:
                  (inputText == "" &&
                    [...recordingParsed, ...fileList].length == 0 &&
                    recordingParsed.length == 0) ||
                  isAdminView
                    ? "#EDEEEF"
                    : "#130D38",
                color: "white",
              }}
              disabled={
                (inputText == "" &&
                  [...recordingParsed, ...fileList].length == 0 &&
                  recordingParsed.length == 0) ||
                isAdminView
              }
              onClick={handleMessageSubmit}
            >
              <Flex>
                <Text
                  style={{
                    color:
                      (inputText == "" &&
                        [...recordingParsed, ...fileList].length == 0 &&
                        recordingParsed.length == 0) ||
                      isAdminView
                        ? "black"
                        : "white",
                  }}
                >
                  {" "}
                  Send{" "}
                </Text>

                <FaArrowUp
                  style={{
                    background:
                      (inputText == "" &&
                        [...recordingParsed, ...fileList].length == 0 &&
                        recordingParsed.length == 0) ||
                      isAdminView
                        ? "white"
                        : "#653BFA",
                    padding: "2px",

                    marginTop: "4px",
                    borderRadius: "25px",
                    marginLeft: "5px",
                  }}
                  color={
                    (inputText == "" &&
                      [...recordingParsed, ...fileList].length == 0 &&
                      recordingParsed.length == 0) ||
                    isAdminView
                      ? "black"
                      : "white"
                  }
                />
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChatTextArea;
