import React from "react";
import { Switch, Typography } from "antd";

interface LabeledSwitchProps {
    label: string;
    name: string;
    configType: string;
    configOption: string;
    defaultChecked?: boolean;
    onChange?: (data: { name: string; configType: string; configOption: string; value: boolean }) => void;
}

const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
    label,
    name,
    configType,
    configOption,
    defaultChecked = false,
    onChange,
}) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                padding: "5px",
                width: "90%",
            }}
        >
            <Typography.Text style={{ fontWeight: "500" }}>{label}</Typography.Text>
            <Switch
                defaultChecked={defaultChecked}
                value={defaultChecked}
                onChange={(checked) =>
                    onChange &&
                    onChange({
                        name,
                        configType,
                        configOption,
                        value: checked,
                    })
                }
            />
        </div>
    );
};

export default LabeledSwitch;
