import { Button, Typography, Divider, Card, Flex, Switch, Select } from "antd";
import LabeledSwitch from "../../components/generic/ConfigSwitch";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useSetAccountConfig, useSetUserConfig, useGetConfig } from "../../hooks/apis/configurations.api";
import Loader from "../../components/generic/Loader";
import { useMessageToast } from "../../components/Layout/DefaultLayout";

const titleStyle: React.CSSProperties = {
    color: "#3e2ab5",
    fontWeight: 600,
    marginBottom: "10px"
};

const descriptionStyle: React.CSSProperties = {
    color: "#8c8c8c",
    marginBottom: "20px",
    fontSize: "14px"
};

const cardStyle: React.CSSProperties = {
    borderRadius: "10px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    padding: "16px",
    minHeight: "400px"
};

const spinStyle: React.CSSProperties = {
    width: "100%",
    height: "calc(100% - 80px)"
};

export interface AccountConfiguration {
    conversations: {
        assign_user_on_null: boolean;
    };
}

export interface UserConfiguration {
    notifications: {
        popups: {
            condition: "always" | "assigned" | "unassigned";
            enabled: boolean;
        };
        sound: {
            enabled: boolean;
            condition: "always" | "assigned" | "unassigned";
        };
    };
}

const Configurations = () => {
    const { selectedAccount } = useSelector((state: RootState) => state.admin);
    const { data: configurations } = useSelector((state: RootState) => state.configurations);
    const { data: user } = useSelector((state: RootState) => state.user);

    const { messageApi } = useMessageToast();

    const { isFetching: isLoading, isFetched } = useGetConfig(selectedAccount);

    const {
        mutate: setAccountConfigMutate,
        isLoading: setAccountConfigLoading,
        isSuccess: setAccountConfigSuccess
    } = useSetAccountConfig(selectedAccount);
    const {
        mutate: setUserConfigMutate,
        isLoading: setUserConfigLoading,
        isSuccess: setUserConfigSuccess
    } = useSetUserConfig(user?.id as string);

    const [accountConfig, setAccountConfig] = useState<AccountConfiguration>({
        conversations: {
            assign_user_on_null: false
        }
    });

    const [userConfig, setUserConfig] = useState<UserConfiguration>({
        notifications: {
            popups: {
                condition: "always",
                enabled: true
            },
            sound: {
                enabled: true,
                condition: "always"
            }
        }
    });

    useEffect(() => {
        if (setAccountConfigSuccess || setUserConfigSuccess) {
            messageApi.open({
                type: "success",
                content: "Configuration updated successfully"
            });
        }
    }, [setAccountConfigSuccess, setUserConfigSuccess]);

    useEffect(() => {
        if (configurations) {
            if (configurations.account) {
                setAccountConfig(configurations.account);
            }
            if (configurations.user) {
                setUserConfig(configurations.user);
            }
        }
    }, [configurations, isLoading]);

    const handleSwitchChange = (data: { name: string; configType: string; configOption: string; value: boolean }) => {
        if (data.configType === "account") {
            setAccountConfig(prev => ({
                ...prev,
                [data.configOption]: {
                    ...prev[data.configOption as keyof AccountConfiguration],
                    [data.name]: data.value
                }
            }));
        } else if (data.configType === "user") {
            setUserConfig(prev => ({
                ...prev,
                [data.configOption]: {
                    ...prev[data.configOption as keyof UserConfiguration],
                    [data.name]: data.value
                }
            }));
        }
    };

    const handleSaveChanges = () => {
        const storeAccountConfig = JSON.stringify(configurations.account);
        const storeUserConfig = JSON.stringify(configurations.user);

        if (configurations.account && storeAccountConfig !== JSON.stringify(accountConfig)) {
            setAccountConfigMutate(accountConfig);
        }

        if (configurations.user && storeUserConfig !== JSON.stringify(userConfig)) {
            setUserConfigMutate(userConfig);
        }
    };

    if (isLoading || !isFetched) {
        return (
            <Flex style={spinStyle} justify="center" align="center">
                <Loader />
            </Flex>
        );
    }

    return (
        <div style={{ padding: "16px", margin: "auto" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <Typography.Title level={3} style={{ color: "#3e2ab5", margin: 0 }}>
                    Configurations
                </Typography.Title>
                <Button
                    loading={setAccountConfigLoading || setUserConfigLoading}
                    onClick={handleSaveChanges}
                    type="primary"
                    style={{ marginLeft: "auto" }}
                    disabled={
                        JSON.stringify(configurations.account) === JSON.stringify(accountConfig) &&
                        JSON.stringify(configurations.user) === JSON.stringify(userConfig)
                    }
                >
                    Save Changes
                </Button>
            </div>

            <div style={{ display: "flex", gap: "20px" }}>
                <Card style={{ ...cardStyle, flex: 1 }} bodyStyle={{ padding: "0px 10px" }} bordered={false}>
                    <Typography.Title level={4} style={titleStyle}>
                        Account Settings
                    </Typography.Title>
                    <Typography.Text style={descriptionStyle}>Customize settings to apply globally for all users.</Typography.Text>
                    <Divider style={{ margin: "10px 0" }} />
                    <LabeledSwitch
                        label="Auto assign user on new message if user is not assigned"
                        name="assign_user_on_null"
                        configType="account"
                        configOption="conversations"
                        defaultChecked={accountConfig.conversations.assign_user_on_null}
                        onChange={handleSwitchChange}
                    />
                </Card>

                <Card style={{ ...cardStyle, flex: 1 }} bodyStyle={{ padding: "0px 10px" }} bordered={false}>
                    <Flex align="center" justify="space-between">
                        <Typography.Title level={4} style={titleStyle}>
                            Notifications
                        </Typography.Title>
                        <Switch
                            checked={userConfig.notifications.popups.enabled}
                            value={userConfig.notifications.popups.enabled}
                            onChange={checked => {
                                setUserConfig(prev => ({
                                    ...prev,
                                    notifications: {
                                        ...prev.notifications,
                                        popups: {
                                            ...prev.notifications.popups,
                                            enabled: checked
                                        }
                                    }
                                }));
                            }}
                        />
                    </Flex>
                    <Typography.Text style={descriptionStyle}>
                        Personalize how you receive notifications to suit your preferences.
                    </Typography.Text>
                    <Divider style={{ margin: "10px 0" }} />
                    {userConfig.notifications.popups.enabled && (
                        <Flex vertical gap={10}>
                            <Flex align="center" justify="space-between">
                                <Typography.Text style={{ ...descriptionStyle, margin: "0px" }}>Popup</Typography.Text>
                                <Select
                                    style={{ width: "150px", textAlign: "center" }}
                                    value={userConfig.notifications.popups.condition}
                                    onChange={value => {
                                        setUserConfig(prev => ({
                                            ...prev,
                                            notifications: {
                                                ...prev.notifications,
                                                popups: {
                                                    ...prev.notifications.popups,
                                                    condition: value
                                                }
                                            }
                                        }));
                                    }}
                                >
                                    <Select.Option value="always">Default (Always)</Select.Option>
                                    <Select.Option value="assigned">Assigned</Select.Option>
                                    <Select.Option value="unassigned">Unassigned</Select.Option>
                                </Select>
                            </Flex>
                            <Flex align="center" justify="space-between">
                                <Typography.Text style={{ ...descriptionStyle, margin: "0px" }}>Sound</Typography.Text>
                                <Select
                                    style={{ width: "150px", textAlign: "center" }}
                                    value={userConfig.notifications.sound.condition}
                                    onChange={value => {
                                        setUserConfig(prev => ({
                                            ...prev,
                                            notifications: {
                                                ...prev.notifications,
                                                sound: {
                                                    ...prev.notifications.sound,
                                                    condition: value
                                                }
                                            }
                                        }));
                                    }}
                                >
                                    <Select.Option value="always">Default (always)</Select.Option>
                                    <Select.Option value="assigned">Assigned</Select.Option>
                                    <Select.Option value="unassigned">Unassigned</Select.Option>
                                </Select>
                            </Flex>
                        </Flex>
                    )}
                </Card>
            </div>
        </div>
    );
};

export default Configurations;
