import Table, { TableProps } from "antd/es/table";
import { deleteTemplateSlice, TemplateType } from "../../../store/slices/templates/templates.slice";
import { Modal, Space, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { useDeleteTemplate, useGetTemplates } from "../../../hooks/apis/templates.api";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import TemplateForm from "../../Form/Template";
import ModalWrapper from "../../generic/modal";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useMessageToast } from "../../Layout/DefaultLayout";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { usePermissions } from "../../../hooks/permissions";
import { GrView } from "react-icons/gr";
import { TemplateInformation } from "../Templates/TemplateInfo";

const { confirm } = Modal;

export const TemplatesTable = ({ searchInput, account_id }: { searchInput: string; account_id: string | null | undefined }) => {
  const { messageApi } = useMessageToast();
  const { hasPermission } = usePermissions();
  const dispatch: AppDispatch = useDispatch();

  const navigate: NavigateFunction = useNavigate();

  const { data: templates } = useSelector((state: RootState) => state.templates);
  const { data: userData } = useSelector((state: RootState) => state.user);

  const [editData, setEditData] = useState<TemplateType | null>(null);
  const [editDataModal, setEditDataModal] = useState<boolean>(false);
  const [viewTemplate, setViewTemplate] = useState<boolean>(false);
  const [viewData, setViewData] = useState<TemplateType | null>(null);

  const { refetch: fetchTemplates, isLoading: isLoadingGetTemplates } = useGetTemplates(account_id);
  const { mutate: deleteTemplate, isLoading: isLoadingDelete } = useDeleteTemplate();

  const showDeleteConfirm = (record: TemplateType) => {
    confirm({
      title: "Are you sure you want to delete this template?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteTemplate(record.id, {
          onSuccess: () => {
            dispatch(deleteTemplateSlice(record.id));
            messageApi.success("Template deleted successfully");
          },
        });
      },
    });
  };

  useEffect(() => {
    fetchTemplates();
  }, [account_id]);

  type ColumnsType<T extends object> = TableProps<T>["columns"];

  const columns: ColumnsType<TemplateType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      render: (_, { name, id }) => (
        <a style={{ textAlign: "left" }} onClick={() => navigate(`/templates/${id}`)}>
          {name}
        </a>
      ),
    },
    {
      title: "Visibility",
      dataIndex: "visibility",
      key: "visibility",
      render: (_, { visibility }) => {
        let color = ""
        if(visibility === "public"){
            color = "#28a745"
        }
        if(visibility === "did"){
           color = "#007bff"
        }
        if(visibility === "private"){
            color = "#fd7e14"
        }
        if(visibility === "user"){
          color = "#20c997"
        }

      return <Tag color={color}>{visibility === "did" ? "numbers" : visibility}</Tag>
    },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {
            <GrView
              color="green"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setViewTemplate(true);
                setViewData(record);
              }}
            />
          }
          {(hasPermission("sms", ["updateTemplates:any", "updateTemplates:account"]) || (hasPermission("sms", "updateTemplates:own") && record.visibility === "private" && record.private_owner_id === userData?.id)) && (
            <CiEdit
              color="blue"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditData(record);
                setEditDataModal(true);
              }}
            />
          )}
          {(hasPermission("sms", ["deleteTemplates:any", "deleteTemplates:account"]) || (hasPermission("sms", "deleteTemplates:own") && record.visibility === "private" && record.private_owner_id === userData?.id)) && 
            (
              <MdDeleteOutline
                color="red"
                onClick={() => {
                  showDeleteConfirm(record);
                }}
                style={{ cursor: "pointer" }}
              />
            )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {editDataModal && (
        <ModalWrapper isModalOpen={editDataModal} setIsModalOpen={setEditDataModal} title="Edit Template">
          <TemplateForm editData={editData} account_id={account_id} setIsModalOpen={setEditDataModal} />
        </ModalWrapper>
      )}
      {viewTemplate && viewData && (
        <ModalWrapper isModalOpen={viewTemplate} setIsModalOpen={setViewTemplate} title="Template Information">
          <TemplateInformation template={viewData} />
        </ModalWrapper>
      )}
      <Table
        className="template-table"
        loading={isLoadingGetTemplates}
        scroll={{ y: 450 }}
        style={{ width: "100%", height: "500px" }}
        columns={columns}
        pagination={{ position: ["bottomCenter"] }}
        dataSource={templates}
      />
    </div>
  );
};
