import { Avatar, Button, Checkbox, Flex, Input, Popover, Spin, Table, TableProps, Tag, Tooltip, TreeSelect, Typography } from "antd";
import { MdOutlinePhone } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { formatPhoneNumber, phoneNumberValidate } from "../../utils/phoneLib";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { generateNameSpaceColor } from "../../utils/color";
import { Contact } from "@data-phone/react-generic/dist/components/Forms/Contacts";
import { IoSearch } from "react-icons/io5";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetContacts } from "../../hooks/apis/contacts.api";
import { useGetCategories } from "../../hooks/apis/categories.api";
import { useGetTagsByCategory } from "../../hooks/apis/tags.api";
import ModalWrapper from "../generic/modal";
import { SelectedContacts } from "../Contacts/SelectedContacts.list";
import { LoadingOutlined } from '@ant-design/icons';


const avatarStyle: React.CSSProperties = {
    verticalAlign: "middle",
    color: "black",
    fontWeight: "bold"
};

const inputSearchStyle: React.CSSProperties = {
    padding: "10px",
    borderRadius: "25px"
};

const searchIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    color: "green",
    cursor: "pointer"
};

export const ContactsTable = ({ setSelectedContacts, selectedContacts }: { setSelectedContacts: Function; selectedContacts: string[] }) => {
    const { refetch: fetchContacts, isLoading: isLoadingContacts } = useGetContacts();
    const { refetch: fetchCategories, isLoading: isLoadingCategories } = useGetCategories();
    const { refetch: fetchTags, isLoading: isLoadingTags } = useGetTagsByCategory();

    const { data: tags } = useSelector((state: RootState) => state.tags);
    const { data: contacts } = useSelector((state: RootState) => state.contacts);
    const { data: categories } = useSelector((state: RootState) => state.categories);

    const [searchTextState, setSearchTextState] = useState("");
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [invalidContacts, setInvalidContacts] = useState<string[]>([]);
    const [invalidContactsModal, setInvalidContactsModal] = useState<boolean>(false);
    const [validContactsModal, setValidContactsModal] = useState<boolean>(false);
    const [isProcessingSelection, setIsProcessingSelection] = useState<boolean>(false);

    useEffect(() => {
        const invalidContacts = selectedContacts.filter(contact => {
            const contactData = contacts.find(c => c.id === contact);
            if (contactData?.phones.length === 0 || contactData?.phones?.every(phone => !phoneNumberValidate(phone.phone_number))) {
                return true;
            }
        });
        setInvalidContacts(invalidContacts);
    }, [selectedContacts]);

    useEffect(() => {
        fetchCategories();
        fetchTags();
        if (contacts.length == 0) {
            fetchContacts();
        }
    }, []);

    const filteredContacts = useMemo(() => {
        return contacts
            .filter(contact => {
                if (searchTextState === "" && selectedTags.length === 0) return true;

                const matchesSearchText =
                    `${contact.first_name || ""} ${contact.middle_name || ""} ${contact.last_name || ""}`
                        .toLowerCase()
                        .includes(searchTextState.toLowerCase()) ||
                    contact.emails.some(email => email.email.toLowerCase().includes(searchTextState.toLowerCase())) ||
                    contact.phones.some(phone => phone.phone_number.includes(searchTextState));

                const matchesSelectedTags =
                    selectedTags.length > 0 && contact.tags && contact.tags.some(tag => selectedTags.includes(tag.tag));

                if (searchTextState && selectedTags.length) return matchesSearchText && matchesSelectedTags;
                if (searchTextState) return matchesSearchText;
                if (selectedTags.length) return matchesSelectedTags;

                return false;
            })
            .map(contact => ({
                ...contact,
                key: contact.id
            }));
    }, [contacts, searchTextState, selectedTags]);

    const handleSearchInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTextState(e.target.value);
    }, []);

    const renderNameColumn = (record: Contact) => (
        <Flex align="center" gap={10}>
            <Avatar style={{ ...avatarStyle, background: generateNameSpaceColor(record.first_name || "") }} size="large">
                {record.first_name?.charAt(0).toUpperCase() || ""}
            </Avatar>
            <Flex vertical>
                <Typography.Text style={{ fontWeight: "500" }}>
                    {record.first_name || ""} {record.middle_name || ""} {record.last_name || ""}
                </Typography.Text>
                <Typography.Text type="secondary">{record.emails.length > 0 ? record.emails[0].email : ""}</Typography.Text>
            </Flex>
        </Flex>
    );

    const renderPhoneColumn = (phones: Contact["phones"]) => (
        <Flex gap="10px" align="center">
            <MdOutlinePhone color="blue" size={20} />
            <Typography.Text strong>{formatPhoneNumber(phones[0]?.phone_number)}</Typography.Text>
            {phones.length > 1 && (
                <Popover
                    content={phones.map(phone => (
                        <Flex key={phone.phone_number} gap="10px" vertical>
                            <Typography.Text>{formatPhoneNumber(phone.phone_number)}</Typography.Text>
                        </Flex>
                    ))}
                >
                    <HiOutlineDotsHorizontal cursor="pointer" />
                </Popover>
            )}
        </Flex>
    );

    const renderTagsColumn = (contactTags: Contact["tags"]) => (
        <Flex>
            {contactTags &&
                contactTags.length &&
                contactTags.length <= 3 &&
                contactTags
                    ?.slice(0, 3)
                    .sort((a, b) => a.tag.localeCompare(b.tag))
                    .map(tag => (
                        <Tag key={tag.tag} className="text-ellipsis" color="blue">
                            <Tooltip title={tags.find(t => t.name === tag.tag)?.name}>{tags.find(t => t.name === tag.tag)?.name}</Tooltip>
                        </Tag>
                    ))}
            {contactTags && contactTags.length > 3 && (
                <Popover
                    content={
                        contactTags &&
                        contactTags.length > 3 &&
                        [...contactTags]
                            ?.sort((a, b) => a.tag.localeCompare(b.tag))
                            ?.map(tag => (
                                <Tag key={tag.tag} color="blue">
                                    <Tooltip title={tags.find(t => t.name === tag.tag)?.name}>
                                        {tags.find(t => t.name === tag.tag)?.name}
                                    </Tooltip>
                                </Tag>
                            ))
                    }
                >
                    <Button style={{ height: "25px" }} icon={<HiOutlineDotsHorizontal />} />
                </Popover>
            )}
        </Flex>
    );

    const columns: TableProps<Contact>["columns"] = [
        {
            key: "name",
            title: "Name",
            render: (_, record) => renderNameColumn(record)
        },
        {
            key: "phone",
            title: "Phone",
            render: (_, record) => renderPhoneColumn(record.phones)
        },
        {
            key: "tags",
            title: "Tags",
            render: (_, record) => renderTagsColumn(record.tags)
        }
    ];

    return (
        <Flex gap={10} vertical>
            <Input
                style={inputSearchStyle}
                onChange={handleSearchInput}
                size="middle"
                placeholder="Search"
                prefix={<IoSearch style={searchIconStyle} />}
            />
            <Flex gap={10} align="center">
                <TreeSelect
                    showSearch
                    style={{ width: "50%", borderRadius: "25px" }}
                    placeholder="Filter by tags"
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    allowClear
                    treeCheckable
                    loading={isLoadingCategories || isLoadingTags}
                    onChange={value => {
                        setSelectedTags(value);
                    }}
                    value={selectedTags}
                    treeData={[
                        ...categories,
                        {
                            name: "Uncategorized",
                            id: "uncategorized"
                        }
                    ].map(category => {
                        return {
                            title: category.name,
                            value: category.name,
                            key: category.name,
                            children: tags
                                .filter(tag => {
                                    if (category.name === "Uncategorized") {
                                        return tag.category === null;
                                    } else if (category.name !== null) {
                                        return tag.category === category.name;
                                    }
                                })
                                .map(tag => {
                                    return {
                                        title: tag.name,
                                        value: tag.name,
                                        key: tag.name
                                    };
                                })
                        };
                    })}
                />
                {selectedContacts.filter(contact => !invalidContacts.includes(contact)).length > 0 && (
                    <Tag onClick={() => setValidContactsModal(true)} style={{ cursor: "pointer" }} color="green">
                        Valid Contacts: {selectedContacts.filter(contact => !invalidContacts.includes(contact)).length}
                    </Tag>
                )}
                {invalidContacts.length > 0 && (
                    <Tag onClick={() => setInvalidContactsModal(true)} style={{ cursor: "pointer" }} color="red">
                        Invalid Contacts: {invalidContacts.length}
                    </Tag>
                )}
            </Flex>
            {invalidContacts.length > 0 && invalidContactsModal && (
                <ModalWrapper isModalOpen={invalidContactsModal} setIsModalOpen={setInvalidContactsModal} title="Invalid Contacts">
                    <SelectedContacts
                        setIsModal={setInvalidContactsModal}
                        type="invalid"
                        contacts={invalidContacts}
                        setSelectedContacts={setSelectedContacts}
                    />
                </ModalWrapper>
            )}
            {validContactsModal && selectedContacts.filter(contact => !invalidContacts.includes(contact)).length > 0 && (
                <ModalWrapper isModalOpen={validContactsModal} setIsModalOpen={setValidContactsModal} title="Valid Contacts">
                    <SelectedContacts
                        setIsModal={setValidContactsModal}
                        type="valid"
                        contacts={selectedContacts.filter(contact => !invalidContacts.includes(contact))}
                        setSelectedContacts={setSelectedContacts}
                    />
                </ModalWrapper>
            )}
            <div style={{ height: "400px", overflowY: "auto" }}>
                <Table<Contact>
                    sticky={{ offsetHeader: 0 }}
                    rowSelection={{
                        type: "checkbox",
                        columnTitle:isProcessingSelection ? <Spin indicator={<LoadingOutlined  spin />}  size="small" /> :undefined,
                        onSelect: (selectedRow, selected) => {
                            if (selected) {
                                setSelectedContacts((prev: string[]) => {
                                    return [...prev, selectedRow.id as string];
                                });
                            } else {
                                setSelectedContacts((prev: string[]) => {
                                    return prev.filter(contact => contact !== selectedRow.id);
                                });
                            }
                        },
                        onSelectAll: selected => {
                            setIsProcessingSelection(true);
                            setTimeout(() => {
                                if (selected) {
                                    setSelectedContacts(filteredContacts.map(contact => contact.id as string));
                                } else {
                                    setSelectedContacts([]);
                                }
                                setIsProcessingSelection(false);
                            }, 0);
                        },
                        selectedRowKeys: selectedContacts,
                        preserveSelectedRowKeys: true
                    }}
                    pagination={{
                        position: ["bottomCenter"]
                    }}
                    loading={isLoadingContacts}
                    rowKey={contact => contact.id || ""}
                    className="contact-table"
                    bordered={false}
                    dataSource={filteredContacts}
                    columns={columns}
                />
            </div>
        </Flex>
    );
};
