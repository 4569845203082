import { createSlice } from "@reduxjs/toolkit";
import { AccountConfiguration, UserConfiguration } from "../../../pages/Settings/Configurations";

interface ConfigurationsState {
    data: {
        account: AccountConfiguration | null;
        user: UserConfiguration;
    };
}

const initialState: ConfigurationsState = {
    data: {
        account: {
            conversations: {
                assign_user_on_null: false
            }
        },
        user: {
            notifications: {
                popups: {
                    enabled: true,
                    condition: "always"
                },
                sound: {
                    enabled: true,
                    condition: "always"
                }
            }
        }
    }
};

const configurationsSlice = createSlice({
    name: "configurations",
    initialState,
    reducers: {
        setUserConfig: (state, action) => {
            state.data.user = action.payload ?? state.data.user;
        },
        setAccountConfig: (state, action) => {
            state.data.account = action.payload ?? state.data.account;
        }
    }
});

export const { setUserConfig, setAccountConfig } = configurationsSlice.actions;
export default configurationsSlice.reducer;
