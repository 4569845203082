import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BulkMessage } from "../../hooks/apis/bulkmessages.api";

interface BulkMessagesState {
  data: BulkMessage[];
}

const initialState: BulkMessagesState = {
  data: [],
};

export const bulkMessages = createSlice({
  name: "bulkMessages",
  initialState,
  reducers: {
    setBulkMessages: (state, action: PayloadAction<BulkMessage[]>) => {
      action.payload.forEach((message) => {
        const existingIndex = state.data.findIndex((item) => item.id === message.id);
        if (existingIndex === -1) {
          state.data.push(message);
        } else {
          state.data[existingIndex] = message;
        }
      });
    },
  },
});

export const { setBulkMessages } = bulkMessages.actions;

export default bulkMessages.reducer;
