import { api } from "@data-phone/react-resource-server-auth";
import { AxiosResponse } from "axios";
import { AccountConfiguration, UserConfiguration } from "../../pages/Settings/Configurations";
import { useMutation, useQuery } from "react-query";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { setAccountConfig, setUserConfig } from "../../store/slices/configurations";

export const getConfigApi = async (
    account_id: string | null
): Promise<{
    accountConfigurations: AccountConfiguration | null;
    userConfigurations: UserConfiguration | null;
}> => {
    const response: AxiosResponse = await api.get(`${process.env.REACT_APP_SMS_API_URL}/configuration/${account_id}`);
    return response.data;
};

export const setAccountConfigApi = async (account_id: string, data: AccountConfiguration):Promise<{
    account_id: string;
    config: AccountConfiguration;
}> => {
    const response: AxiosResponse = await api.post(`${process.env.REACT_APP_SMS_API_URL}/configuration/account`, {
        account_id,
        config: data
    });
    return response.data;
};

export const setUserConfigApi = async (user_id: string, data: UserConfiguration): Promise<{
    user_id: string;
    config: UserConfiguration;
}> => {
    const response: AxiosResponse = await api.post(`${process.env.REACT_APP_SMS_API_URL}/configuration/user`, {
        user_id,
        config: data
    });
    return response.data;
};


export const useGetConfig = (account_id: string) => {
    const dispatch: AppDispatch = useDispatch();

    return useQuery([`config-${account_id}`], () => getConfigApi(account_id), {
        onSuccess(data) {
            dispatch(setAccountConfig(data.accountConfigurations));
            dispatch(setUserConfig(data.userConfigurations));
        },
        enabled: false
    });
};

export const useSetAccountConfig = (account_id: string) => {
    const dispatch: AppDispatch = useDispatch();
    return useMutation((data: AccountConfiguration) => setAccountConfigApi(account_id, data), {
        onSuccess: data => {
            dispatch(setAccountConfig(data.config));
        }
    });
};

export const useSetUserConfig = (user_id: string) => {
    const dispatch: AppDispatch = useDispatch();
    return useMutation((data: UserConfiguration) => setUserConfigApi(user_id, data), {
        onSuccess: data => {
            dispatch(setUserConfig(data.config));
        }
    });
};


