import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  BaseMessage,
  deliveryStatusMessage,
  incomingMessage,
  outgoingMessage,
} from "../../interfaces/messages.type";
import {
  updateConversationByConversationEvent,
  updateConversationsByIncomingMessage
} from "../../store/slices/conversation.slice";
import {
  updateUserDidCounterByIncomingMessage,
} from "../../store/slices/userDids.slice";
import {
  onMessage,
  deleteMessage as deleteMessageSlice,
  updateDeliveryStatusSlice
} from "../../store/slices/messages.slice";
import {
  Conversation,
} from "../../interfaces/conversations.type";
import { useMessageToast } from "../../components/Layout/DefaultLayout";
import { useSocket } from "@data-phone/react-resource-server-auth";
import { deleteOfflineMessage, setOfflineMessage } from "../../store/slices/offline_messages.slice";
import useMessageNotification from "../notifications/messageNotifications";


export const useMessageSocket = () => {
  const socketRef: any = useRef<WebSocket | null>(null);
  const disconnectionToast = useRef<any>(null)
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const { sendMessageNotification } = useMessageNotification();

  const dispatch: AppDispatch = useDispatch();
  const { messageApi } = useMessageToast();


  const { data: user } = useSelector(
    (state: RootState) => state.user
  );

  const { socket } = useSocket({
    socketUrl: process.env.REACT_APP_SMS_MESSAGE_SOCKET_URL as string,
    path: process.env.REACT_APP_IS_PRODUCTION === "true" ? "/messages/socket.io" : "",
  })



  useEffect(() => {
    if (socket) {
      setIsSocketConnected(true);

      if (socket.connected) {
        console.log("Connected to message socket");
      }
      socket.on("message", handleMessageEvent);
      socket.on("conversation-update", handleUpdateConversation);
      socket.on("disconnect", handleDisconnectEvent);
      socket.io.on("reconnect", handleReconnectEvent);
      socket.io.on("reconnect_attempt", handleReconnectAttemptEvent);
      socket.io.on("reconnect_error", handleReconnectErrorEvent);
      socket.on("refresh-required", handleRefreshRequiredEvent);
      socket.on("message-delete", handleDeleteMessageEvent)
      socket.on("message-delivery-status", handleMessageDeliveryStatusEvent);

      socketRef.current = socket;
    }
    return () => {
      if (socket) {
        socket.disconnect();
        socketRef.current = null;
      }
    };
  }, [socket]);

  const sendOutgoingMessage = (message: outgoingMessage) => {
    const baseMessage: BaseMessage = {
      clientId: message.clientId,
      did: message.from,
      sender_id: user?.id as string,
      participant: message.to,
      body: message.text,
      media: message.media,
      direction: "outbound",
      timestamp: new Date().toISOString(),
      user_id: user?.id as string,
      deleted: false,
      first_name: user?.firstName ? user?.firstName : null,
      last_name: user?.lastName ? user?.lastName : null,
      status: "pending",
      delete_timestamp: null,
    };

    if (socketRef.current) {
      dispatch(onMessage(baseMessage));
      dispatch(updateConversationsByIncomingMessage(baseMessage));
      dispatch(deleteOfflineMessage(baseMessage.clientId as string))
      socketRef.current.emit("message", message);
    }
    else {
      baseMessage.offlineMessage = true
      dispatch(setOfflineMessage(baseMessage))
      return
    }
  };



  const deleteMessage = (messageId: string) => {
    if (socketRef.current) {
      socketRef.current.emit("message-delete", messageId);
    }
  }

  const handleDeleteMessageEvent = (messageId: string) => {
    dispatch(deleteMessageSlice(messageId))
  }

  const handleMessageDeliveryStatusEvent = (message: deliveryStatusMessage) => {
    dispatch(updateDeliveryStatusSlice(message))
  }


  const handleMessageEvent = (message: incomingMessage) => {
    dispatch(updateUserDidCounterByIncomingMessage({ ...message, user_id: user?.id }));
    const newMessage: BaseMessage = {
      ...message,
      user_id: user?.id as string,
      delete_timestamp: null,
      deleted: false,
      first_name: message.first_name || null,
      last_name: message.last_name || null,
    };
    dispatch(updateConversationsByIncomingMessage(newMessage));
    dispatch(onMessage(newMessage));
    if (message.direction == "inbound") {
      sendMessageNotification(message);
    }
  };
  const updateConversation = (conversation: {
    did: string;
    participant: string;
    last_read_timestamp?: string;
    assigned_user?: string;
    resolved?: boolean;
  }) => {
    if (socketRef.current) {
      socketRef.current.emit("conversation-update", conversation);
    }
  };

  const handleUpdateConversation = (conversation: Conversation) => {
    dispatch(updateConversationByConversationEvent(conversation));
    if (!Object.hasOwnProperty.call(conversation, 'last_read_timestamp')) {
      messageApi.success("conversation updated")
    }
  };


  const handleDisconnectEvent = (error: any) => {

    console.log("Disconnected from message socket", error);

    if (disconnectionToast.current) {
      disconnectionToast.current.destroy();
    }

    if(window.location.pathname == "/"){
      messageApi.open({
        type: "loading",
        content: "Connection to message server lost - reconnecting",
        duration: 0,
      });
    }

    if (socketRef.current) {
      socketRef.current = null;
    }
    
    setIsSocketConnected(false);
  };

  const handleReconnectEvent = (attempt: number) => {
    console.log("Reconnected to message socket", attempt);


    messageApi.destroy();
    messageApi.success("Connected");

    socketRef.current = socket;
    setIsSocketConnected(true);
  };

  const handleReconnectAttemptEvent = (attempt: number) => {
    console.log("Attempting to reconnect to message socket", attempt);
  };

  const handleReconnectErrorEvent = (error: any) => {
    console.log("Error while reconnecting to message socket", error);
  };

  const handleRefreshRequiredEvent = (data: any) => {

    console.log("Refresh required from message socket", data);
  };

  return {
    sendOutgoingMessage,
    updateConversation,
    deleteMessage,
    isSocketConnected
  };
};
