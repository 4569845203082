import { BulkMessage, useGetBulkMessagesJobs } from "../../hooks/apis/bulkmessages.api";
import Table, { ColumnsType } from "antd/es/table";
import { formatPhoneNumber } from "../../utils/phoneLib";
import { Avatar, Flex, Tag, Typography } from "antd";
import phoneImg from "../../assets/images/icon/Phone-call.svg";
import { formatChatDate } from "../../utils/dateFormat";
import { FaEye } from "react-icons/fa";

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const avatarStyle: React.CSSProperties = {
  height: "1.2rem",
  width: "1.2rem",
  marginTop: "2px",
};

const CountTag = ({ color, text }: { color: string; text: string }) => <Tag color={color}>{text}</Tag>;

const BulkMessagesTable = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const { data: bulkMessages } = useSelector((state: RootState) => state.bulkMessages);
  const { data: userData } = useSelector((state: RootState) => state.user);

  const [pageSize] = useState(20);

  const { isLoading, refetch: refetchBulkMessages } = useGetBulkMessagesJobs({
    offset: page ? (parseInt(page) - 1) * pageSize : 0,
    limit: pageSize,
    account: userData?.accountID as string,
  });

  useEffect(() => {
    refetchBulkMessages();
  }, [page]);

  const columns: ColumnsType<BulkMessage> = [
    {
      title: "Label",
      dataIndex: "label",
      align: "center",
      key: "label",
      render: (_, { label }) => <Typography.Text style={{ fontWeight: "500" }}>{label}</Typography.Text>,
    },
    {
      title: "Number",
      dataIndex: "did",
      align: "center",
      onHeaderCell: () => ({ style: { width: 200 } }),
      onCell: () => ({ style: { width: 200 } }),
      key: "did",
      render: (_, { did }) => (
        <Flex justify="center" gap={10}>
          <Avatar style={avatarStyle} src={phoneImg} />
          <Typography.Text style={{ fontWeight: "500" }}>{formatPhoneNumber(did)}</Typography.Text>
        </Flex>
      ),
    },
    {
      title: "Total",
      align: "center",
      dataIndex: "total",
      onHeaderCell: () => ({ style: { width: 85 } }),
      onCell: () => ({ style: { width: 85 } }),
      key: "total",
      render: (_, { total }) => <CountTag color="blue" text={`${total}`} />,
    },
    {
      title: "Sent",
      align: "center",
      onHeaderCell: () => ({ style: { width: 85 } }),
      onCell: () => ({ style: { width: 85 } }),
      dataIndex: "sent",
      key: "sent",
      render: (_, { sent }) => <CountTag color="green" text={`${sent}`} />,
    },
    {
      title: "Failed",
      align: "center",
      onHeaderCell: () => ({ style: { width: 85 } }),
      onCell: () => ({ style: { width: 85 } }),
      dataIndex: "failed",
      key: "failed",
      render: (_, { failed }) => <CountTag color="red" text={`${failed}`} />,
    },
    {
      title: "Pending",
      align: "center",
      dataIndex: "pending",
      onHeaderCell: () => ({ style: { width: 90 } }),
      onCell: () => ({ style: { width: 90 } }),
      key: "pending",
      render: (_, { pending }) => <CountTag color="cyan" text={`${pending}`} />,
    },
    {
        title: "Scheduled",
        align: "center",
        dataIndex: "scheduled",
        onHeaderCell: () => ({ style: { width: 90 } }),
        onCell: () => ({ style: { width: 90 } }),
        key: "scheduled",
        render: (_, { scheduled }) => <CountTag color="cyan" text={`${scheduled}`} />,
    },
    {
      title: "Sent By",
      dataIndex: "sender_id",
      align: "center",
      key: "sender_id",
      render: (_, { first_name, last_name }) => (
        <Typography.Text style={{ fontWeight: "500" }}>
          {first_name} {last_name}
        </Typography.Text>
      ),
    },
    {
      title: "Date and Time",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (_, { created_at }) => <Typography.Text style={{ fontWeight: "500", minWidth: "200px" }}>{dayjs(created_at).format("MM/DD/YYYY hh:mm A")}</Typography.Text>,
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      render: (_, { pending , scheduled}) => <Tag color={Number(pending) > 0 || Number(scheduled) > 0 ? "cyan" : "green"} >{Number(pending) > 0 ? "In Progress" : Number(scheduled) > 0 ? "Scheduled" : "Completed"}</Tag>,
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "action",
      key: "action",
      render: (_, { id }) => (
        <FaEye
          cursor={"pointer"}
          onClick={() => {
            navigate(`/bulk/message/${id}`);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <Table
        pagination={{
          onChange: (page) => {
            navigate(`/bulk/list/${page}`);
          },
          current: Number(page),
          pageSize: pageSize,
          total: bulkMessages?.length + 1,
          position: ["none", "bottomCenter"],
        }}
        loading={isLoading}
        columns={columns}
        dataSource={[...bulkMessages].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
      />
    </div>
  );
};

export default BulkMessagesTable;
