import axios, { AxiosResponse } from "axios";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { Conversation } from "../../interfaces/conversations.type";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { setConversations } from "../../store/slices/conversation.slice";
import { api } from "@data-phone/react-resource-server-auth";
import qs from "qs";

export interface ConversationQuery {
  did: string | null;
  limit: number;
  offset: number;
  unread?: boolean;
  assigned?: boolean;
  unassigned?: boolean;
  resolved?: boolean;
  assigned_user?: string | null;
  unresolved?: boolean;
  account?: string | null;
  participant?: string | null;
  searchQuery?: string | null;
}

export interface SearchConversationQuery {
  did: string
  search: string
  participants?: string[]
  searchType: "conversation" | "contact"
  before: string
}

export interface SearchedConversation {
  body: string
  did: string
  participant: string
  id: string
  position: number
  timestamp: string
}


export const fetchConversationsData = async (
  query: ConversationQuery | null,
  offset: number,
  dispatch: AppDispatch
): Promise<Conversation[]> => {

  if(!query) return []

  const queryStr = qs.stringify({
    limit: query.limit,
    offset: offset,
    participant: query.participant,
    assigned: query.assigned,
    assigned_user: query.assigned_user,
    unresolved: query.unresolved,
    unread: query.unread,
    resolved: query.resolved,
    unassigned: query.unassigned,
    account: query.account || undefined,
    search: query.searchQuery
  })

  const response: AxiosResponse<Conversation[]> = await api.get(`${process.env.REACT_APP_SMS_API_URL}/sms/conversations/${query.did}?${queryStr}`);

  dispatch(setConversations({ data: response.data, query: query, offset: offset }));
  return response.data;
};



const searchConversationsApi = async (searchQuery: SearchConversationQuery) => {
  const queryStr = qs.stringify({
    search: searchQuery.search,
    participants: searchQuery.participants,
    before: searchQuery.before,
    limit: 20
  }, { arrayFormat: "brackets" })

  const response: AxiosResponse<SearchedConversation[]> = await api.get(
    `${process.env.REACT_APP_SMS_API_URL}/sms/conversations/${searchQuery.did}/search/${searchQuery.searchType === "contact" ? "participants" : "messages"}?${queryStr}`,
  )
  return response.data
}

const useGetConversations = (query: ConversationQuery | null) => {
  
  const dispatch: AppDispatch = useDispatch();

  return useInfiniteQuery<Conversation[], Error>({
    queryKey: ["conversations", query],
    queryFn: ({ pageParam = 0 }) => fetchConversationsData(query, pageParam, dispatch),
    getNextPageParam(lastPage, allPages) {
      return lastPage.length > 0 ? allPages.length * 20 : undefined;
    },
    enabled: false
  });
};

export const useSearchConversations = () => {
  return useMutation<SearchedConversation[], Error, SearchConversationQuery>((query) => searchConversationsApi(query), { mutationKey: ["searchConversations"] })
}


export default useGetConversations;
