import { Button, Checkbox, Flex, List, Popover, Typography } from "antd";
import { MdOutlinePhone } from "react-icons/md";
import { formatPhoneNumber, phoneNumberValidate } from "../../utils/phoneLib";
import { useState } from "react";
import { ParsedContacts } from "../../pages/BulkMessages";

export const InvalidCsvList = ({ contacts, setParsedContacts, setIsModal, type }: { contacts: ParsedContacts[]; setParsedContacts: Function; setIsModal: Function; type: "valid" | "invalid" }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  return (
    <Flex style={{ padding: "10px 10px", maxHeight: "500px", overflow: "auto" }} vertical>
      <Checkbox
        onChange={() => {
          if (selectedRowKeys.length === contacts.length) {
            setSelectedRowKeys([]);
          } else {
            setSelectedRowKeys(
              contacts
                .map((contact, index) => 
                  !phoneNumberValidate(contact.number) ? index : null
                )
                .filter((key): key is number => key !== null) 
            );
          }
        }}
        checked={selectedRowKeys.length === contacts.length}
      >
        Select All
      </Checkbox>
      <List>
        {
          [...contacts].map((item, index) =>
            !phoneNumberValidate(item.number) ? (
              <List.Item key={index}>
                <Flex style={{ width: "100%" }} align="center" gap={"10px"}>
                  <Flex gap={"10px"} align={"center"}>
                    <Checkbox
                      checked={selectedRowKeys.includes(index)}
                      onChange={() => {
                        setSelectedRowKeys((prev) => {
                          if (prev.includes(index)) {
                            return prev.filter((key) => key !== index);
                          } else {
                            return [...prev, index];
                          }
                        });
                      }}
                    />
                  </Flex>
                  <Flex gap={"10px"} align={"center"}>
                    {" "}
                    <MdOutlinePhone color="blue" size={20} />
                    {formatPhoneNumber(item?.number)}
                    {`- ${index}`}
                  </Flex>
                </Flex>
              </List.Item>
            ) : null
          )
        }
      </List>
      <Button
        type="primary"
        onClick={() => {
          setParsedContacts((prev: ParsedContacts[]) => {
            return prev.filter((contact, index) => !selectedRowKeys.includes(index));
          });
          setIsModal(false);
        }}
        disabled={selectedRowKeys.length === 0}
      >
        Remove Selected
      </Button>
    </Flex>
  );
};
