import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userDidsReducer from "./slices/userDids.slice";
import conversationReducer from "./slices/conversation.slice";
import messagesReducer from "./slices/messages.slice";
import userReducer from "./slices/user.slice"
import autoReplyRules from "./slices/auto_reply_rule";
import templates from "./slices/templates/templates.slice"
import didUsersSlice from "./slices/didUsers.slice"
import adminSlice from "./slices/admin.slice";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import ringotelOrgSlice from "./slices/ringotelOrg.slice";
import ringotelUsersSlice from "./slices/ringotelUsers.slice";
import contactSlice from "./slices/contact.slice";
import offlineMessagesSlice from "./slices/offline_messages.slice";
import bulkMessages from "./slices/bulk_messages.slice";
import scheduledMessages from "./slices/scheduled_messages.slice";
import tags from "./slices/tags.slice";
import categories from "./slices/categories.slice";
import templateDids from "./slices/templates/templateDids.slice";
import templateUsers from "./slices/templates/user_templates.slice";
import configurations from "./slices/configurations/index"

const rootReducer = combineReducers({
  userDids: userDidsReducer,
  conversation: conversationReducer,
  messages: messagesReducer,
  user: userReducer,
  autoReplyRules: autoReplyRules,
  templates: templates,
  didUsers: didUsersSlice,
  admin: adminSlice,
  ringotelOrgs: ringotelOrgSlice,
  ringotelUsers: ringotelUsersSlice,
  contacts: contactSlice,
  offlineMessages: offlineMessagesSlice,
  templateDids:templateDids,
  templateUsers:templateUsers,
  bulkMessages,
  scheduledMessages,
  tags,
  categories,
  configurations
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['admin', 'offlineMessages'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
