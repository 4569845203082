import { selectedConversaton, setSelectedConversation } from "../../../store/slices/conversation.slice";
import phone from "../../../assets/images/icon/Phone-call-msg.svg";
import assign from "../../../assets/images/icon/Assign.svg";
import resolve from "../../../assets/images/icon/Check-circle.svg";
import newContact from "../../../assets/images/icon/New contact.svg";
import download from "../../../assets/images/icon/Download.svg";
import blockNumber from "../../../assets/images/icon/Block number.svg";
import markAsUnread from "../../../assets/images/icon/Mark as unread.svg";
import deleteIcon from "../../../assets/images/icon/Delete.svg";
import { Flex, Image, Popover, Typography } from "antd";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../generic/modal";
import { useState } from "react";
import { UsersList } from "../didUsers/user.list";
import AddContactModal from "../../Contacts/AddContactModal";
import ExistingContactModal from "../../Contacts/existingContactModal";
import { updateUserDidConversationCounter } from "../../../store/slices/userDids.slice";
import { Contact } from "@data-phone/react-generic/dist/components/Forms/Contacts";

const { Text } = Typography;
const ImageStyle: React.CSSProperties = {
  width: "28px",
  height: "28px",
  objectFit: "contain",
  objectPosition: "center",
  display: "block",
  margin: "auto",
};
const ContentWrapper: React.CSSProperties = {
  padding: "10px",
};

const PhoneTextStyle: React.CSSProperties = {
  textAlign: "center",
};

const BoxStyle: React.CSSProperties = {
  paddingTop: "10px",
};

const ChatSideBar = ({ selectedConversation, updateConversation }: { selectedConversation: selectedConversaton; updateConversation: Function }) => {
  const { data: conversations } = useSelector((state: RootState) => state.conversation);
  const { data: contacts } = useSelector((state: RootState) => state.contacts);
  const { isAdminView } = useSelector((state: RootState) => state.admin);

  const dispatch: AppDispatch = useDispatch();
  const [isAssignConversation, setIsAssignConversation] = useState(false);
  const [addContactModal, setAddContactModal] = useState(false);
  const [existingContactModal, setExistingContactModal] = useState(false);
  const [editData, setEditData] = useState<Contact | null>(null);

  const MarkAsUnread = () => {
    if (selectedConversation) {
      updateConversation({
        did: selectedConversation.did,
        participant: selectedConversation.participant,
        unread: true,
      });
    }

    dispatch(setSelectedConversation(null));
    dispatch(updateUserDidConversationCounter(selectedConversation.did));
  };

  const data = [
    {
      name: "Call",
      icon: phone,
      disabled: true,
      popOver: null,
      action: () => {},
    },
    {
      name: "Assign",
      icon: assign,
      disabled: false,
      popOver: null,
      action: () => {
        if (selectedConversation) {
          setIsAssignConversation(true);
        }
      },
    },
    {
      name: "Resolve",
      icon: resolve,
      popOver: null,
      disabled: conversations.find((conversation) => {
        return conversation.did === selectedConversation?.did && conversation.participant === selectedConversation?.participant;
      })?.resolved
        ? true
        : false,
      action: () => {
        if (selectedConversation) {
          const conversation = conversations.find((conversation) => {
            return conversation.did === selectedConversation?.did && conversation.participant === selectedConversation?.participant;
          });
          if (conversation) {
            updateConversation({
              did: conversation.did,
              participant: conversation.participant,
              resolved_at: new Date(),
            });
          }
        }
      },
    },
    {
      name: "Contact",
      icon: newContact,
      disabled: false,
      popOver: (
        <div>
          <p
            style={
              contacts && contacts.some((contact) => contact.phones.some((phone) => phone.phone_number === selectedConversation?.participant))
                ? { cursor: "not-allowed", color: "gray" }
                : { cursor: "pointer" }
            }
            onClick={() => {
              if (contacts && contacts.some((contact) => contact.phones.some((phone) => phone.phone_number === selectedConversation?.participant))) return;
              setAddContactModal(true);
            }}
          >
            New Contact
          </p>
          <p
            style={
              contacts.some((contact) => contact.phones.some((phone) => phone.phone_number === selectedConversation?.participant)) ? { cursor: "pointer" } : { cursor: "not-allowed", color: "gray" }
            }
            onClick={() => {
              if (contacts && contacts.some((contact) => contact.phones.some((phone) => phone.phone_number === selectedConversation?.participant))) {
                let contact = contacts.find((contact) => contact.phones.some((phone) => phone.phone_number === selectedConversation?.participant));
                setExistingContactModal(true);
                if (contact) {
                  setEditData(contact);
                }
              }
            }}
          >
            Edit Contact
          </p>
        </div>
      ),
      action: () => {},
    },
    {
      name: "Download",
      icon: download,
      disabled: true,
      popOver: null,
      action: () => {},
    },
    {
      name: "Block Number",
      icon: blockNumber,
      disabled: true,
      popOver: null,
      action: () => {},
    },
    {
      name: "Mark as Unread",
      icon: markAsUnread,
      disabled:
        isAdminView ||
        conversations.find((conversation) => {
          return conversation.did === selectedConversation?.did && conversation.participant === selectedConversation?.participant;
        })?.unread,
      popOver: null,
      action: () => {
        MarkAsUnread();
      },
    },
    {
      name: "Delete",
      icon: deleteIcon,
      disabled: true,
      popOver: null,
      action: () => {
        if (selectedConversation) {
        }
      },
    },
  ];

  return (
    <Flex style={BoxStyle} vertical={true}>
      {addContactModal && <AddContactModal initialNumber={selectedConversation?.participant} setIsModalOpen={setAddContactModal} isModalOpen={addContactModal} />}
      {existingContactModal && editData && <ExistingContactModal setIsModalOpen={setExistingContactModal} isModalOpen={existingContactModal} editData={editData} />}
      {isAssignConversation && (
        <ModalWrapper title={"Assign"} setIsModalOpen={setIsAssignConversation} isModalOpen={isAssignConversation}>
          <UsersList updateConversation={updateConversation} setIsModalOpen={setIsAssignConversation} isModalOpen={isAssignConversation} conversation={selectedConversation} />
        </ModalWrapper>
      )}

      {data.map((item, index) =>
        item.popOver ? (
          <Popover placement="left" content={item.popOver} key={index}>
            <Flex
              style={{
                ...ContentWrapper,
                opacity: item.disabled ? "0.3" : 1,
                cursor: item.disabled ? "not-allowed" : "pointer",
              }}
              onClick={item.disabled ? () => {} : item.action}
              key={index}
              vertical={true}
            >
              <Image style={ImageStyle} preview={false} src={item.icon} alt={item.name} />
              <Text style={PhoneTextStyle}>{item.name}</Text>
            </Flex>
          </Popover>
        ) : (
          <Flex
            style={{
              ...ContentWrapper,
              opacity: item.disabled ? "0.3" : 1,
              cursor: item.disabled ? "not-allowed" : "pointer",
            }}
            onClick={item.disabled ? () => {} : item.action}
            key={index}
            vertical={true}
          >
            <Image style={ImageStyle} preview={false} src={item.icon} alt={item.name} />
            <Text style={PhoneTextStyle}>{item.name}</Text>
          </Flex>
        )
      )}
    </Flex>
  );
};

export default ChatSideBar;
