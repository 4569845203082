import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { api } from "@data-phone/react-resource-server-auth";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { setBulkMessages } from "../../store/slices/bulk_messages.slice";

export interface OutgoingBulkMessage {
    to: string;
    text: string;
}

export interface BulkMessage {
    id: string;
    did: string;
    label: string;
    first_name: string;
    last_name: string;
    total: number;
    sent: number;
    failed: number;
    pending: number;
    scheduled: number;
    created_at: string;
}

export interface BulkMessageData {
    bulk_message_id: string;
    status: string;
    participant: string;
    timestamp: string;
    scheduled_at?: string;
    body: string;
}

export interface BulkMessageStatus {
    bulk_message_id: string;
    status: string;
    participant: string;
    updated_at: string;
    body: string;
}

export interface bulkMessageQuery {
    limit: number;
    offset: number;
    did?: string;
    account: string
    status?: string;
    fromDate?: string | null;
    toDate?: string | null;
}

const getBulkMessagesApi = async (query: bulkMessageQuery, dispatch: AppDispatch) => {
    const response = await api.get(
        `${process.env.REACT_APP_SMS_API_URL}/bulk?limit=${query.limit
        }&offset=${query.offset}&account=${query.account}${query.status ? `&status=${query.status}` : ""
        }${query.did ? `&did=${query.did}` : ""}`
    );
    dispatch(setBulkMessages(response.data));
    return response.data;
}

const getBulkMessageDetailsApi = async (id: string): Promise<BulkMessageData[]> => {
    const response = await api.get(
        `${process.env.REACT_APP_SMS_API_URL}/bulk/${id}`
    );
    return response.data;
}


const sendBulkMessageApi = async (data: {
    messages: OutgoingBulkMessage[],
    from: string
}) => {
    const response = await api.post(
        `${process.env.REACT_APP_SMS_API_URL}/sms/send-bulk`,
        data
    );
    return response.data;
}

export const useSendBulkMessage = () => {
    return useMutation((data: {
        messages: OutgoingBulkMessage[],
        from: string,
        label: string,
        scheduled_at?:Date ,
    }) => sendBulkMessageApi(data), {
    })
}


export const useGetBulkMessagesJobs = (query: bulkMessageQuery) => {

    const dispatch: AppDispatch = useDispatch();
    

    return useQuery<BulkMessage[], Error>({
        queryKey: ["bulkmessages", query],
        queryFn: () => getBulkMessagesApi(query, dispatch),
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.length > 0 ? allPages.length * 20 : undefined;
        },
        enabled: false
    });


}


export const useGetBulkMessageDetails = (id: string) => {
    return useQuery<BulkMessageData[], Error>({
        queryKey: ["bulkmessages", id],
        queryFn: () => getBulkMessageDetailsApi(id),
    });
}