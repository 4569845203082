import { incomingMessage } from "../../interfaces/messages.type";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect, useRef } from "react";
import { Conversation } from "../../interfaces/conversations.type";
import { fetchConversationsData } from "../apis/conversations.api";
import { AccountConfiguration, UserConfiguration } from "../../pages/Settings/Configurations";
import { formatPhoneNumber, phoneNumberFormatE164 } from "../../utils/phoneLib";
import { Contact } from "@data-phone/react-generic/dist/components/Forms/Contacts";
import { setSelectedDid } from "../../store/slices/userDids.slice";
import { setSelectedConversation } from "../../store/slices/conversation.slice";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../../assets/images/logo/Logomark Coloured.png";

const useMessageNotification = () => {
    const dispatch: AppDispatch = useDispatch();
    const { data: conversations } = useSelector((state: RootState) => state.conversation);
    const { data: user } = useSelector((state: RootState) => state.user);
    const { data: configurations } = useSelector((state: RootState) => state.configurations);
    const { data: contacts } = useSelector((state: RootState) => state.contacts);

    const navigate = useNavigate();

    const conversationsRef = useRef<Conversation[]>(conversations);
    const configurationsRef = useRef<{
        account: AccountConfiguration | null;
        user: UserConfiguration | null;
    }>(configurations);
    const contactsRef = useRef<Contact[]>(contacts);

    useEffect(() => {
        contactsRef.current = contacts;
    }, [contacts]);

    useEffect(() => {
        configurationsRef.current = configurations;
    }, [configurations]);

    useEffect(() => {
        conversationsRef.current = conversations;
    }, [conversations]);

    const getConversation = async (did: string, participant: string): Promise<Conversation | undefined> => {
        const conversation = await fetchConversationsData({ did: did, participant: participant, limit: 1, offset: 0 }, 0, dispatch);

        if (conversation.length > 0) {
            return conversation[0];
        }

        return undefined;
    };

    const shouldPlaySound = (config: UserConfiguration, isAssignedCase: boolean, isUnassignedCase: boolean): boolean => {
        const { enabled, condition } = config.notifications.sound;

        if (!enabled) {
            return false;
        }

        if (condition === "always") {
            return true;
        }

        if (condition === "assigned" && isAssignedCase) {
            return true;
        }

        if (condition === "unassigned" && isUnassignedCase) {
            return true;
        }

        return false;
    };

    const shouldShowNotification = (config: UserConfiguration, isAssignedCase: boolean, isUnassignedCase: boolean): boolean => {
        const { enabled, condition } = config.notifications.popups;

        if (!enabled) {
            return false;
        }

        if (condition === "always") {
            return true;
        }

        if (condition === "assigned" && isAssignedCase) {
            return true;
        }

        if (condition === "unassigned" && isUnassignedCase) {
            return true;
        }

        return false;
    };

    const sendMessageNotification = async (message: incomingMessage) => {
        let conversation = conversationsRef.current.find(item => item.did === message.did && item.participant === message.participant);

        if (!conversation) {
            conversation = await getConversation(message.did, message.participant);
        }

        if (!conversation) {
            return;
        }

        const contact = contactsRef.current.find(item =>
            item.phones.some(phone => phoneNumberFormatE164(phone.phone_number) === message.participant)
        );

        const isAssignedCase = conversation.assigned_user === user?.id;
        const isUnassignedCase = conversation.assigned_user === null;

        if (!configurationsRef.current.user) {
            return;
        }

        if (!shouldShowNotification(configurationsRef.current.user, isAssignedCase, isUnassignedCase)) {
            return;
        }

        const title = contact
            ? `${contact.first_name}${contact.middle_name ? " " + contact.middle_name : ""}${
                  contact.last_name ? " " + contact.last_name : ""
              }`
            : (formatPhoneNumber(message.participant) as string);

        const notificationPayload = {
            icon: LogoIcon,
            body: message.body,
            silent: !shouldPlaySound(configurationsRef.current.user, isAssignedCase, isUnassignedCase)
        };

        let notification: Notification | undefined;

        if (Notification.permission === "granted") {
            notification = new Notification(title, notificationPayload);
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    notification = new Notification(title, notificationPayload);
                }
            });
        }

        if (notification) {
            notification.onclick = () => {
                window.focus();
                dispatch(setSelectedDid(message.did));
                dispatch(
                    setSelectedConversation({
                        did: message.did,
                        participant: message.participant
                    })
                );
                navigate("/");
                notification?.close();
            };
        }
    };

    return {
        sendMessageNotification
    };
};

export default useMessageNotification;
