export function findTemplatePlaceHolders(template: string): string[] {
    const regex = /{{(.*?)}}/g;

    const matches: string[] = [];
    let match: RegExpExecArray | null;

    while ((match = regex.exec(template)) !== null) {
        matches.push(match[1]);
    }

    return matches;
}

export function replacePlaceholders(template: string, variables: Record<string, string>): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            const result = template.replace(/{{(\w+)}}/g, (placeholder, key) => {
                if (!variables.hasOwnProperty(key) || variables[key] === "") {
                    return ""
                }
                return variables[key];
            });
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });

}