import { NavigateFunction } from "react-router-dom";

export const pageChange = (page:number, navigate:NavigateFunction) =>{
    if(page < 1){
        page = 1
    }
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    queryParams.set("page", page.toString());
    const newUrl = `${url.pathname}?${queryParams.toString()}`;
    navigate(newUrl);
}