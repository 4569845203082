import { Layout, ThemeConfig } from "antd";
import { Link, Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { message, notification } from "antd";
import { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import { LayoutHeader, SideBar } from "@data-phone/react-generic";
import { GrTemplate } from "react-icons/gr";
import { MdOutlineSchedule } from "react-icons/md";
import { TbCode } from "react-icons/tb";
import { FaMailBulk, FaSms } from "react-icons/fa";
import Sider from "antd/es/layout/Sider";
import { usePermissions } from "../../hooks/permissions";
import { GrDocumentConfig } from "react-icons/gr";
import { useGetCategories } from "../../hooks/apis/categories.api";
import { useGetTagsByCategory } from "../../hooks/apis/tags.api";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { useGetUserDids } from "../../hooks/apis/userDids";
import { setSelectedAccount } from "../../store/slices/admin.slice";
import { setSelectedConversation } from "../../store/slices/conversation.slice";
import { setSelectedDid } from "../../store/slices/userDids.slice";
import { IoSettingsOutline } from "react-icons/io5";
import { useGetConfig } from "../../hooks/apis/configurations.api";
import { useGetContacts } from "../../hooks/apis/contacts.api";


const contentStyle: React.CSSProperties = {
  height: "100%",
  borderRight: "1px solid rgb(223, 226, 232)",
  background: "#f5f5f5",
};

const DefaultLayout = ({ logout }: { logout: () => void }) => {
  const navigate = useNavigate();

  const layoutStyle: React.CSSProperties = {
    height: "100vh",
    background: "#ffffff !important",
  };
  const dispatch: AppDispatch = useDispatch();
  const { hasPermission } = usePermissions();

  const user = useSelector((state: RootState) => state.user.data);
  const [messageApi, contextHolder] = message.useMessage();
  const { selectedAccount, isAdminView } = useSelector((state: RootState) => state.admin);

  const { refetch } = useGetUserDids(hasPermission("sms", ["readUserDids:any", "readUserDids:account"]) && isAdminView && selectedAccount ? selectedAccount : null);
  const { refetch: fetchContacts } = useGetContacts();


  useEffect(() => {
    if ((isAdminView && selectedAccount == "") || !isAdminView) {
      dispatch(setSelectedAccount(user?.accountID as string));
    }
    refetch();
    dispatch(setSelectedConversation(null));
    dispatch(setSelectedDid(null));
  }, [isAdminView, selectedAccount]);

  const [collapsed, setCollapsed] = useState(true);

  const { refetch: fetchCategories, isLoading: isLoadingCategories } = useGetCategories();
  const { refetch: fetchTags, isLoading: isLoadingTags } = useGetTagsByCategory();

  // config
  const {refetch: fetchConfig} = useGetConfig(selectedAccount);


  useEffect(() => {
    fetchCategories();
    fetchTags();
    fetchConfig();
    fetchContacts();
  }, []);


  const menu: {
    key: string;
    icon: JSX.Element;
    label: JSX.Element;
  }[] = [
    {
      key: "",
      icon: <FaSms color="#3e2ab5" />,
      label: <Link to="/">SMS</Link>,
      permission: true,
    },
    {
      key: "bulk",
      icon: <FaMailBulk color="#3e2ab5" />,
      label: <Link to="/bulk/list/1">Bulk SMS</Link>,
      permission: true,
    },
    {
      key: "scheduled-messages",
      icon: <MdOutlineSchedule color="#3e2ab5" />,
      label: <Link to="/scheduled-messages">Schedule</Link>,
      permission: true,
    },
    {
      key: "numbers",
      icon: <GrDocumentConfig color="#3e2ab5" />,
      label: <Link to="/numbers">Numbers</Link>,
      permission: hasPermission("sms", ["readDids:any", "readDids:account"]),
    },
    {
      key: "templates",
      icon: <GrTemplate color="#3e2ab5" />,
      label:  <Link to="/templates">Templates</Link> ,
      permission: null,
    },
    {
      key: "integrations",
      icon: <TbCode color="#3e2ab5" />,
      label: <Link to="/integrations">Integrations</Link>,
      permission: hasPermission("users", ["read:any"]),
    },
    {
      key: "reports",
      icon: <HiOutlineDocumentReport color="#3e2ab5" />,
      label: <Link to="/reports?page=1">Reports</Link>,
      permission: true,
    },
    {
      key: "configurations",
      icon: <IoSettingsOutline color="#3e2ab5" />,
      label: <Link to="/configurations">Settings</Link>,
      permission: null,
    }
  ]
    .filter((item) => item.permission !== false)
    .map((item) => {
      return {
        key: item.key,
        label: item.label,
        icon: item.icon,
      };
    });

  return (
    <ConfigProvider
      theme={
        {
          token: {
            fontFamily: "Rethink Sans",
            colorPrimary: "#3e2ab5",
            controlItemBgActive: "#e2dff8",
          },
          components: {
            Segmented: {
              itemSelectedColor: "white",
              itemSelectedBg: "#3e2ab5",
              colorInfoActive: "white",
              colorSuccessActive: "white",
            },
          },
        } as ThemeConfig
      }
    >
      <Layout style={layoutStyle}>
        {contextHolder}
        <LayoutHeader navigate={navigate} logout={logout} name={`${user?.firstName} ${user?.lastName}`} permissions={user?.permissions} />
        <Layout>
          <Sider style={contentStyle} collapsed={collapsed} onCollapse={(collapsed) => setCollapsed(collapsed)} trigger={null}>
            <SideBar menu={menu} collapsed={collapsed} setCollapsed={setCollapsed} />
          </Sider>
          <Content>
            <Outlet context={{ messageApi }} />
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default DefaultLayout;

export function useMessageToast() {
  return useOutletContext<any>();
}
